import React from "react";

export default function PrivacyTerms() {
  const empresa = process.env.REACT_APP_NAME_EMPRESA;
  const link = process.env.REACT_APP_LINK_EMPRESA;
  const email = process.env.REACT_APP_MAIL_EMPRESA;

  return (
    <div className="container mt-4 mb-4">
      <div className="row terms">
        <h1>POLÍTICA DE PRIVACIDADE</h1>
        <p>
          Seja bem-vindo à plataforma {empresa}! A Política de Privacidade a
          seguir é baseada em nossos princípios e valores. Levamos muito a sério
          sua privacidade e, por isso, protegemos seus dados pessoais.<br></br>
          <br></br>
          Esta Política de Privacidade e Proteção de Dados descreve nossas
          políticas sobre as informações que possam estar associadas ou permitem
          a identificação de uma pessoa (Dados Pessoais) e informações que não
          se relacionam a uma pessoa e/ou não podem ser usadas para identificar
          uma pessoa (Dados não Pessoais) em relação ao site da Web. <br></br>
          <br></br>
          Como condição para acesso e utilização da plataforma {empresa} e suas
          funcionalidades, você declara que realizou a leitura completa e atenta
          das regras deste documento, estando plenamente ciente e de acordo com
          elas.
        </p>
        <h4>
          <strong>DEFINIÇÕES</strong>
        </h4>
        <p>
          <b>a) Consumidor:</b> termo utilizado para designar pessoas que
          adquirem ingressos para os eventos criados pelos próprios
          Organizadores.<br></br>
          <b>b) Controlador de dados pessoais:</b> pessoa natural ou jurídica,
          de direito público ou privado, a quem competem as decisões referentes
          ao tratamento de dados pessoais.<br></br>
          <b>c) Cookies:</b> arquivos que têm a finalidade de identificar um
          computador e obter dados de acesso, como páginas navegadas ou links
          clicados, permitindo, desta forma, personalizar a navegação destes, de
          acordo com o perfil do usuário.<br></br>
          <b>d) Dado Pessoal:</b> informação relacionada a pessoa natural
          identificada ou identificável.<br></br>
          <b>e) Dado Não Pessoal:</b> quaisquer informações que não se
          relacionem com uma pessoa e/ou não possam ser usados para identificar
          uma pessoa.<br></br>
          <b>f) Operador de dados pessoais:</b> pessoa natural ou jurídica, de
          direito público ou privado, que realiza o tratamento de dados pessoais
          em nome do controlador.<br></br>
          <b>g) Organizador:</b> termo utilizado para designar pessoas (físicas
          ou jurídicas) que criam eventos próprios através da plataforma{" "}
          {empresa} e os divulgam para Consumidores.<br></br>
          <b>h) Participantes:</b> termo utilizado para designar pessoas que
          usufruem dos ingressos adquiridos pelos Consumidores. Caso os
          Consumidores adquiram ingressos em nome próprio serão também
          Participantes. Por outro lado, caso o Consumidor adquira ingressos em
          proveito de terceiros, estes serão considerados tão somente
          Participantes, e não Consumidores.<br></br>
          <b>i) Plataforma {empresa}:</b> designa a plataforma tecnológica
          disponível para organizadores e casas de eventos, facilitando a venda
          de ingressos, inscrição para eventos, cobrança de contribuições para
          eventos e a gestão de participantes, acessível no endereço eletrônico{" "}
          {link}.<br></br>
          <b>j) Tratamento de dados pessoais:</b> toda operação realizada com
          dados pessoais, como as que se referem a coleta, produção, recepção,
          classificação, utilização, acesso, reprodução, transmissão,
          distribuição, processamento, arquivamento, armazenamento, eliminação,
          avaliação ou controle da informação, modificação, comunicação,
          transferência, difusão ou extração.<br></br>
          <b>k) Usuário:</b> termo utilizado para designar, quando referidos em
          conjunto, Organizadores, Consumidores e pessoas que navegam na
          plataforma {empresa}.<br></br>
        </p>
        <h4>
          <strong>1. Obtenção de informações pessoais e não pessoais</strong>
        </h4>
        <p>
          1.1. Ao utilizar a plataforma {empresa}, serão coletadas algumas
          informações, inclusive dados pessoais. A {empresa} coletará tais
          informações em nome próprio ou, em nome do Organizador, que utiliza a
          plataforma {empresa} para criação, gestão e divulgação de evento
          próprio. Esta distinção é importante para diferenciar as situações em
          que a {empresa} atua na condição de controladora ou operadora de dados
          pessoais.<br></br>
          1.2. A {empresa} coletará os dados pessoais de todos os usuários
          sempre que estes fornecerem tais informações de forma voluntária, por
          exemplo, quando navegarem ou utilizarem a plataforma {empresa} ou
          qualquer de suas funcionalidades, ou quando entrarem em contato com a{" "}
          {empresa}. Os dados pessoais que podemos coletar incluem: nome, data
          de nascimento, número do CPF, endereço eletrônico, endereço de
          correspondência, senha, número de telefone e preferências de contato,
          entre outros necessários para atingir os propósitos dispostos nesta
          Política.<br></br>
          1.3. Além das informações acima mencionadas, o usuário poderá fornecer
          outros tipos de informações, tais como, dados de pagamento,
          informações de geolocalização, comportamento de uso do aplicativo ou
          seus produtos e, informações referentes ao dispositivo computacional
          utilizado para navegar ou utilizar a plataforma {empresa}, como por
          exemplo: endereços IP, tipo de navegador e idioma, provedor de
          serviços de Internet, páginas de consulta e saída, sistema
          operacional, informações sobre data e horário, dados sobre a sequência
          de cliques, fabricante do dispositivo, operadora, modelo, redes Wi-Fi
          e número de telefone.<br></br>
          1.4. A {empresa} poderá também obter informações não pessoais e
          informações sobre as atividades dos usuários na plataforma {empresa}.
          Tais informações poderão ser agregadas e usadas para ajudar a{" "}
          {empresa} a fornecer informações para os Organizadores e entender
          quais partes dos produtos e serviços atraem mais interesse dos
          Consumidores. Dados agregados são considerados informações
          não-pessoais para fins desta Política de Privacidade.<br></br>
          1.4.1. Caso a {empresa} combine informações não-pessoais com
          informações pessoais, a informação combinada será tratada como
          informação pessoal enquanto essa informação permanecer combinada.
          <br></br>
          1.4.1.1. Caso a informação combinada seja considerada sensível, ou
          seja, capaz de revelar dados relacionados à origem racial ou étnica,
          convicção religiosa, opinião política, filiação a sindicato ou a
          organização de caráter religioso, filosófico ou político, dado
          referente à saúde ou à vida sexual, dado genético ou biométrico, o
          tratamento dessas informações será realizada tão somente mediante o
          consentimento do titular. Tais informações serão armazenadas de
          maneira segura e não serão compartilhadas com terceiros, salvo após
          processo de anonimização.<br></br>
          1.5. A {empresa} poderá coletar informações adicionais de
          Organizadores, tais como informações financeiras, de modo a garantir
          determinados pagamentos e emissão de Nota Fiscal. <br></br>
          1.6. A {empresa} poderá coletar informações adicionais de
          Consumidores, por exemplo, quando adquirirem ingressos pagos, caso em
          que o usuário fornecerá dados pessoais financeiros (ex.: número de
          cartão de crédito e data de expiração, endereço de cobrança etc.).
          <br></br>
          1.6.1. Os Organizadores poderão configurar, a seu exclusivo critério,
          a página de seus eventos para coletarem outras informações pessoais
          dos Consumidores. Nestes casos, a {empresa}, na condição de operadora
          de dados pessoais, não interferirá no processo de tratamento de dados
          realizado pelo Organizador, nem sobre quais dados pessoais serão
          coletados ou como serão utilizados por este.<br></br>
          1.6.2. Os dados pessoais coletados em nome dos Organizadores ficam
          disponíveis para estes. Dessa maneira, os Organizadores, na condição
          de controladores de dados pessoais, são responsáveis pelas operações
          de tratamento que realizam sobre estes dados, tais como,
          compartilhamento, análise, armazenamento, entre outros.<br></br>
          1.6.3. A {empresa} possui documento específico (“Acordo de
          Processamento de Dados”), que estabelece seus direitos e obrigações,
          na condição de Operadora de dados pessoais, referente às operações de
          tratamento realizadas em nome dos Organizadores.<br></br>
          1.6.4. Os titulares de dados pessoais deverão exercer os direitos
          previstos no item “7” deste documento perante os Organizadores,
          podendo a {empresa} operacionalizar tais requisições em determinados
          casos.<br></br>
          1.7. A {empresa} poderá, também, obter informações de seus usuários a
          partir de fontes diversas, tais como sites de terceiros, instituições
          bancárias, processadores de pagamento, e outras empresas ou agências
          de proteção de crédito. Nesta atividade serão observadas as regras
          contidas nas legislações aplicáveis.<br></br>
          1.8. Para fins do disposto na Lei nº 13.709/2018 (“Lei Geral de
          Proteção de Dados Pessoais”), a {empresa} poderá atuar tanto na
          qualidade de controladora de dados pessoais (ex.: recomendação de
          eventos na plataforma {empresa}), quanto operadora de dados pessoais
          (ex.: armazenamento de dados pessoais coletados a partir do formulário
          personalizado do Organizador), a depender das circunstâncias e caso
          concreto.<br></br>
        </p>
        <h4>
          <strong>2. Utilização das informações pessoais</strong>
        </h4>
        <p>
          2.1. Os dados pessoais serão utilizados de forma compatível com o
          disposto nesta Política de Privacidade, com objetivo de prover,
          desenvolver e melhorar os serviços prestados ou produtos oferecidos,
          inclusive aqueles a serem criados.<br></br>
          2.2. A {empresa} utilizará os dados pessoais das seguintes maneiras:
          <br></br>
          a) fins internos e gerenciais, tais como auditoria, análise de dados e
          pesquisas para aprimorar os produtos, serviços e comunicações com os
          clientes, e geração de análises estatísticas com respeito ao uso dos
          nossos serviços, incluindo tendências de consumo;<br></br>
          b) enviar avisos e notificações importantes, como comunicados sobre
          compras, alterações em prazos, condições e políticas. Como estas
          informações são importantes para interação com a {empresa}, o usuário
          não poderá optar por não receber esse tipo de comunicação, já que são
          inerentes ao uso da plataforma {empresa};<br></br>
          c) aprimorar a segurança e melhorar os serviços e as ofertas da{" "}
          {empresa}, bem como analisar e solucionar problemas técnicos, além de
          identificar e coibir fraudes na utilização da plataforma {empresa};
          <br></br>
          d) enviar publicidade, bem como outros materiais promocionais voltados
          ao marketing de nossos serviços e de novidades para usuários, o que
          inclui campanhas digitais (tais como marketing direcionado em redes
          sociais e notificações) e não digitais (como divulgação em
          radiodifusão, outdoors, panfletos, entre outros).<br></br>
          2.2.1. A {empresa} realizará o tratamento dos dados pessoais com base
          nas seguintes hipóteses legais: cumprimento de obrigação legal,
          execução de contrato, exercício regular de direitos, atendimento de
          interesses legítimos. A base legal do tratamento poderá variar
          conforme a atividade realizada.<br></br>
          2.3. A {empresa} permite que os Organizadores usem a plataforma{" "}
          {empresa} e suas funcionalidades para entrarem em contato com os
          Consumidores e outros usuários em relação aos seus eventos. Essa
          ferramenta funciona como uma caixa de e-mails padrão, onde o
          Organizador pode inserir os destinatários automaticamente (através das
          listas de e-mail associadas aos seus eventos hospedados na plataforma{" "}
          {empresa}) ou manualmente (através de listas de e-mails coletados fora
          da plataforma {empresa}) e então efetuar os envios. Os comunicados
          serão enviados pelo remetente {email}.<br></br>
          2.3.1. O Organizador é exclusivamente responsável pelo conteúdo e
          envio dessas comunicações, cabendo a este também cessar tal envio,
          sempre que solicitado pelo destinatário. O Organizador também é
          responsável pelo tratamento dos dados pessoais dos titulares
          destinatários dessas comunicações.<br></br>
          2.3.2. O Consumidor fica ciente que o descadastramento da lista de
          e-mail de um Organizador não impede o recebimento de comunicações
          enviadas por outros Organizadores através da plataforma {empresa}.
          Para tanto, os Consumidores deverão bloquear o remetente {email} em
          seu provedor de e-mail.<br></br>
          2.4. Caso a {empresa} utilize quaisquer dados pessoais de forma
          diversa das estabelecidas nesta Política de Privacidade, o usuário
          será previamente informado sobre essa nova utilização, antes ou na
          data em que os dados pessoais forem recebidos.<br></br>
        </p>

        <h4>
          <strong>3. Compartilhamento de informações pessoais</strong>
        </h4>

        <p>
          3.1. A {empresa} não comercializa os dados pessoais de seus usuários a
          terceiros, porém existem situações nas quais a {empresa} poderá
          divulgar, transferir ou compartilhar os dados pessoais com terceiros,
          conforme disposto neste documento e na legislação brasileira.<br></br>
          3.2. A {empresa} poderá vender ou comprar outras empresas ou ativos.
          Em caso de venda, fusão, reorganização, dissolução da empresa ou outra
          operação societária semelhante, os dados pessoais poderão fazer parte
          dos ativos intangíveis compartilhados ou transferidos.<br></br>
          3.2.1. A {empresa} poderá realizar o compartilhamento de dados
          pessoais com empresas do mesmo grupo econômico, com as seguintes
          finalidades: (i) o desenvolvimento de novos produtos e serviços; (ii)
          a oferta de produtos e serviços que melhor atendam aos interesses dos
          usuários; (iii) geração de dados estatísticos e agregados acerca do
          uso de produtos e serviços e perfis dos usuários. <br></br>
          3.3. A {empresa} poderá compartilhar os dados pessoais de seus
          usuários com contratados e prestadores de serviços que tratam tais
          dados em nome da {empresa}, de modo a desempenhar determinadas funções
          relacionadas com as atividades da {empresa}, tais como, mas não
          somente, agências de marketing, prestadores de serviços de bancos de
          dados, prestadores de serviços de recuperação após desastres e de
          cópias de segurança, prestadores de serviços de e-mail, processadores
          de pagamentos e outros. <br></br>
          3.4. A {empresa} poderá compartilhar as informações de seus usuários
          com autoridades policiais ou judiciais, autoridades públicas
          competentes ou outros terceiros, dentro e fora do Brasil, caso seja
          requerido pela legislação aplicável, por decisão judicial e por
          requisição de autoridades, ou se necessário para responder a processos
          judiciais ou para participar em eventuais litígios ou disputas de
          qualquer natureza.<br></br>
          3.4.1. Nestas situações, a {empresa} cooperará com as autoridades
          competentes na medida em que, discricionariamente, entenda necessário
          e adequado em relação a qualquer investigação de ilícitos, infrações a
          direitos de propriedade industrial ou intelectual, ou outra atividade
          que seja ilegal ou que possa expor a {empresa} ou seus usuários a
          qualquer responsabilidade legal ou lhes impor riscos, ressalvadas
          hipóteses de sigilo de informações constantes na legislação aplicável.
          <br></br>
          3.5. A {empresa} se reserva o direito de compartilhar informações
          sobre seus usuários a terceiros quando houver motivos suficientes para
          considerar que a atividade de um usuário é suspeita, ilegal ou
          prejudicial à {empresa} ou a terceiros.<br></br>
        </p>
        <h4>
          <strong>4. Armazenamento de informações pessoais</strong>
        </h4>
        <p>
          4.1. As informações pessoais coletadas pela {empresa} serão
          armazenadas em serviços de nuvem confiáveis, providos por parceiros
          que podem estar localizados no Brasil ou em outros países, tais como
          Estados Unidos da América (EUA) e em países da União Europeia.
          <br></br>
          4.1.1. Na contratação desses serviços, a {empresa} buscará empresas
          que empregam alto nível de segurança no armazenamento de suas
          informações, estabelecendo contratos que não violam as definições de
          privacidade previstas nesta Política.<br></br>
          4.2. A {empresa} armazena as informações dos usuários tão somente pelo
          período necessário para as finalidades apresentadas nos Termos de Uso
          e nesta Política de Privacidade, respeitando sempre o período de
          retenção de dados determinado pela legislação aplicável.<br></br>
          4.2.1. Caso o usuário solicite a exclusão de sua conta, as informações
          pessoais fornecidas durante a utilização da plataforma {empresa} serão
          anonimizadas ou excluídas definitivamente, salvo se tais informações
          forem necessárias para cumprimento de obrigação legal pela {empresa},
          atendimento de interesses legítimos, ou para exercício regular de
          direitos em processo judicial, administrativo ou arbitral. <br></br>
          4.3. A {empresa} emprega seus melhores esforços para respeitar e
          proteger as informações pessoais dos usuários contra perda, roubo ou
          qualquer modalidade de uso indevido, bem como contra acesso não
          autorizado, divulgação, alteração e destruição. <br></br>
          4.3.1. A {empresa} realizará o tratamento de dados pessoais mediante
          alto grau de segurança, implementando as melhores práticas em uso na
          indústria para a proteção de dados, tais como técnicas de
          criptografia, monitoração e testes de segurança periódicos, firewall,
          entre outros. Contudo, não é possível garantir completamente a não
          ocorrência de interceptações e violações dos sistemas e bases de
          dados, uma vez que a internet possui sua estrutura de segurança em
          permanente aperfeiçoamento.<br></br>
          4.3.2. Em caso de incidente de segurança da informação que resulte na
          destruição, perda, alteração, acesso não-autorizado, ou vazamento de
          dados pessoais, a {empresa} irá avaliar, imediatamente, os riscos às
          liberdades civis e aos direitos fundamentais dos titulares dos dados
          pessoais. A comunicação aos titulares ou à Autoridade Nacional de
          Proteção de Dados será realizada conforme o caso concreto, após
          avaliação dos riscos mencionados.<br></br>
        </p>
        <h4>
          <strong>5. Transferência Internacional de dados</strong>
        </h4>
        <p>
          5.1. A {empresa} poderá realizar transferências internacionais de
          dados para outros países, tais como Estados Unidos da América e para
          países da União Europeia, a fim de realizar algumas das atividades
          envolvidas nos serviços prestados aos usuários, bem como para poder
          obter informações que possam contribuir para o aperfeiçoamento e
          segurança dos nossos serviços.<br></br>
          5.2. Na hipótese de compartilhamento com parceiros localizados em
          outros países, estabelecemos contratualmente que o parceiro possua
          padrão de proteção de dados e segurança da informação compatível com
          esta política de privacidade, a fim de que os dados sejam sempre
          protegidos nestes termos.<br></br>
        </p>
        <h4>
          <strong>6. Direitos dos usuários</strong>
        </h4>
        <p>
          6.1. Os usuários poderão solicitar à {empresa}, de forma fácil e
          acessível, através do nosso formulário web no Portal de Privacidade ou
          do e-mail {email}: (i) a confirmação da existência de tratamento de
          dados pessoais; (ii) o acessos aos dados pessoais; (iii) a correção de
          dados incompletos, inexatos ou desatualizados; (iv) anonimização,
          bloqueio ou eliminação dos dados desnecessários ou excessivos; (v)
          portabilidade dos dados, dentro dos limites legais.<br></br>
          6.2. Se um usuário iniciar uma solicitação de exclusão de dados de sua
          titularidade, a {empresa} estará autorizada a excluir ou anonimizar os
          dados pessoais do solicitante, mesmo que isso signifique remover sua
          disponibilidade para o Organizador na plataforma {empresa}. Todavia,
          os dados pessoais ainda poderão estar disponíveis nos próprios bancos
          de dados do Organizador se tiverem sido transmitidos ao Organizador
          antes da {empresa} receber ou tomar medidas referentes à exclusão ou
          anonimização.<br></br>
        </p>
        <h4>
          <strong>
            7. Utilização da plataforma {empresa} por menores e incapazes
          </strong>
        </h4>
        <p>
          7.1. Ao se cadastrar na plataforma {empresa}, o usuário garante de
          forma expressa que é plenamente capaz, nos termos da legislação
          vigente, para exercer e gozar de todos os Serviços. Ademais, os
          Usuários menores de 18 (dezoito) anos deverão obter o consentimento
          expresso de seus pais, tutores ou representantes legais para utilizar
          a plataforma {empresa} e suas funcionalidades, conforme as disposições
          dos Termos de Uso e da Política de Privacidade. <br></br>
          7.2. Os pais, tutores ou representantes legais serão plenamente
          responsáveis também no caso de acesso à plataforma {empresa} por parte
          de crianças e adolescentes, sem a devida obtenção de autorização
          prévia. Cabe a eles a integral responsabilidade pela fiscalização das
          atividades e conduta dos respectivos menores sob sua tutela, bem como
          ciência da integralidade dos presentes Termos.<br></br>
        </p>
        <h4>
          <strong>8. Cookies e outras tecnologias</strong>
        </h4>
        <p>
          8.1. A {empresa} possui política própria sobre o uso de cookies e
          outras tecnologias de rastreamento semelhantes, denominada “Política
          de Cookies”. <br></br>
        </p>
        <h4>
          <strong>9. Disposições Gerais</strong>
        </h4>
        <p>
          9.1. Ao navegar pela plataforma {empresa} e utilizar suas
          funcionalidades, os usuários aceitam todo o disposto nesta Política de
          Privacidade e demais políticas legais que se encontram vigentes na
          data de acesso. Por isso, é recomendável que os usuários se mantenham
          atualizados. <br></br>
          9.2. A presente Política de Privacidade está sujeita a constante
          melhoria e aprimoramento. Assim, a {empresa} se reserva o direito de
          modificá-la a qualquer momento, conforme a finalidade da plataforma{" "}
          {empresa}, tal qual para adequação e conformidade legal de disposição
          de lei ou norma que tenha força jurídica equivalente, cabendo aos
          usuários verificá-la sempre que efetuar o acesso à plataforma{" "}
          {empresa}.<br></br>
          9.3. A eventual tolerância quanto a qualquer violação dos termos e
          condições do contido neste domínio será considerada mera liberalidade
          e não será interpretada como novação, precedente invocável, renúncia a
          direitos, alteração tácita dos termos contratuais, direito adquirido
          ou alteração contratual.<br></br>
          9.4. Caso alguma disposição desta Política for julgada inaplicável ou
          sem efeito, o restante das normas continua a viger, sem a necessidade
          de medida judicial que declare tal assertiva. Os termos aqui descritos
          serão interpretados segundo a legislação brasileira.<br></br>
          9.5. A comunicação entre a {empresa} e o usuário deverá ser realizada
          pelos canais de atendimento indicados e disponibilizados na plataforma{" "}
          {empresa}, sobretudo através do endereço de e-mail {email}.<br></br>
          9.6. Fica eleito o foro da Comarca de Fortaleza, Estado do Ceará, para
          dirimir quaisquer dúvidas oriundas do presente Contrato, excluindo-se
          qualquer outro foro, por mais privilegiado que seja.<br></br>
        </p>
        <h4>
          <strong>TERMO DE CONSENTIMENTO</strong>
        </h4>
        <p>
          Em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados
          Pessoais (LGPD), o cliente autoriza e tem expresso conhecimento da
          utilização dos dados pessoais coletados (nome, documento, e-mail e
          senha), sendo de legítimo interesse a coleta dos dados em razão do que
          dispõe o inciso V, 7º da legislação, para que possamos enviar as
          condições do produto/serviço contratado e para que possamos manter o
          usuário integralmente informado do andamento de solicitações,
          reclamações eventualmente enviadas ao nosso conhecimento e
          providências, e envio de informativos, divulgação de serviços e/ou
          produtos.
        </p>

        <h4>
          <strong>
            GUARDA CONSERVAÇÃO E NÃO COMPARTILHAMENTO DE DADOS PESSOAIS
          </strong>
        </h4>
        <p>
          a) A {empresa} atua em observância as regras de complience na ocasião
          dos tratamentos de dados, garantindo assim a guarda e conservação,
          utilizando todos os mecanismos disponível para proteção das
          informações disponibilizadas pelo cliente, e salvo para eventual
          necessidade do cumprimento deste contrato, não divulga ou compartilha
          nenhuma informação aqui coletada com terceiros.
          <br></br>
          b) Não serão coletados dados sensíveis de nossos usuários, assim
          entendidos aqueles definidos nos arts. 11 e seguintes da Lei de
          Proteção de Dados Pessoais (Lei n. º 13.709/2018). Assim, não haverá
          coleta de dados sobre origem racial ou étnica, convicção religiosa,
          opinião política, filiação a sindicato ou a organização de caráter
          religioso, filosófico ou político, dado referente à saúde ou à vida
          sexual, dado genético ou biométrico, quando vinculado a uma pessoa
          natural.
          <br></br>
          c) Os dados pessoais coletados pelo site são armazenados e utilizados
          por período de tempo que corresponda ao necessário para atingir as
          finalidades elencadas neste documento e que considere os direitos de
          seus titulares, os direitos do controlador do site e as disposições
          legais ou regulatórias aplicáveis. Uma vez expirados os períodos de
          armazenamento dos dados pessoais, eles são removidos de nossas bases
          de dados ou anonimizados, salvo nos casos em que houver a
          possibilidade ou a necessidade de armazenamento em virtude de
          disposição legal ou regulatória.
        </p>
      </div>
    </div>
  );
}
