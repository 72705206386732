import React from "react";

export default function CookiesTerms() {
  const empresa = process.env.REACT_APP_NAME_EMPRESA;
  const link = process.env.REACT_APP_LINK_EMPRESA;
  const email = process.env.REACT_APP_MAIL_EMPRESA2;
  return (
    <div className="container mt-4 mb-4">
      <div className="row terms">
        <h1>POLÍTICA DE COOKIES</h1>
        <p>
          Seja bem-vindo à plataforma {empresa}! A “Política de Cookies” a
          seguir é baseada em nossos princípios e valores. Levamos muito a sério
          sua privacidade e, por isso, protegemos seus dados pessoais.<br></br>
          <br></br>
          Nosso site utiliza “cookies” para melhorar o desempenho, assegurar a
          segurança, aprimorar a sua experiência de navegação para que possamos
          oferecer uma navegação mais personalizada.<br></br>
          <br></br>
          As informações obtidas por meio de cookies não permitem a
          identificação de dados pessoais do usuário e, portanto, são tratadas
          pela {empresa} como informações não-pessoais, porém caso ocorra alguma
          alteração na legislação aplicável e esses dados passem a ser
          considerados informações pessoais, a {empresa} observará a legislação
          pertinente.<br></br>
          <br></br>
          Essa política fornece as principais informações relacionadas à
          utilização de cookies em nosso site.<br></br>
          <br></br>
        </p>
        <h4>DEFINIÇÕES</h4>
        <p>
          Cookies são pequenos arquivos de texto baixados automaticamente em seu
          dispositivo quando você acessa e navega por um site. Eles servem,
          basicamente, para seja possível identificar dispositivos, atividades e
          preferências de usuários. Os cookies não permitem que qualquer arquivo
          ou informação sejam extraídos do disco rígido do usuário, não sendo
          possível, ainda, que, por meio deles, se tenha acesso a informações
          pessoais que não tenham partido do usuário ou da forma como utiliza os
          recursos do site. a. Cookies do site - Os cookies do site são aqueles
          enviados ao computador ou dispositivo do usuário e administrador
          exclusivamente pelo site. b. As informações coletadas por meio destes
          cookies são utilizadas para melhorar e personalizar a experiência do
          usuário, sendo que alguns cookies podem, por exemplo, ser utilizados
          para lembrar as preferências e escolhas do usuário, bem como para o
          oferecimento de conteúdo personalizado que possa lhe interessar.
        </p>
        <p>
          <b>a) Armazenamento local:</b> tecnologia padrão da indústria que
          permite que um website ou aplicativo armazene e recupere dados do
          computador, celular ou outro dispositivo de um usuário.<br></br>
          <b>b) Identificador de dispositivo:</b> códigos que possibilitam a
          identificação do um dispositivo móvel, seja de maneira persistente ou
          transitória, tais como o ID de Publicidade ou o ID do sistema
          operacional.<br></br>
          <b>c) Pixel tag:</b> pequenos blocos de código em uma página da web
          que permitem que elas realizem ações como ler e armazenar cookies e
          transmitir informações para a {empresa}. A conexão resultante pode
          incluir informações como o endereço de IP de um dispositivo, a hora em
          que uma pessoa visualizou o pixel, um identificador associado ao
          navegador ou dispositivo e o tipo de navegador em uso.<br></br>
          <b>d) Plataforma {empresa}:</b> designa a plataforma tecnológica
          disponível para organizadores e casas de eventos, facilitando a venda
          de ingressos, inscrição para eventos, cobrança de contribuições para
          eventos e a gestão de participantes, acessível no endereço eletrônico{" "}
          {link}.<br></br>
          <b>e) Tratamento de dados pessoais:</b> toda operação realizada com
          dados pessoais, como as que se referem a coleta, produção, recepção,
          classificação, utilização, acesso, reprodução, transmissão,
          distribuição, processamento, arquivamento, armazenamento, eliminação,
          avaliação ou controle da informação, modificação, comunicação,
          transferência, difusão ou extração.<br></br>
        </p>
        <h4>Considerações sobre cookies</h4>
        <p>
          1.1. Cookies são arquivos que contém um identificador (uma sequência
          de letras e números) enviados por um servidor para determinado
          navegador (browser) que o armazena. O identificador é então enviado de
          volta ao servidor sempre que o navegador tenta acessar uma página do
          servidor.<br></br>
          1.2. Os cookies podem ser “persistentes” ou “de sessão”. Um cookie
          persistente será armazenado por um navegador e permanecerá válido até
          sua data de validade, salvo se deletado pelo usuário antes desta data.
          Um cookie de sessão, por outro lado, irá expirar ao final de uma
          sessão de navegação do usuário, quando o navegador é encerrado.
          <br></br>
          1.3. A {empresa} trata as informações obtidas por meio de cookies e
          outras tecnologias como informações não-pessoais. Entretanto, na
          medida em que o endereço IP ou identificadores semelhantes sejam
          considerados informações pessoais pela legislação aplicável, a{" "}
          {empresa} tratará esses identificadores como informação pessoal.
          <br></br>
        </p>
        <h4>2. Utilização de cookies</h4>
        <p>
          2.1. A {empresa} utiliza cookies e outras tecnologias com as seguintes
          finalidades:<br></br>
          a) Autenticação: indica que o usuário está conectado, para que seja
          possível oferecer os recursos apropriados, bem como entender como este
          utiliza e acessa a plataforma {empresa};<br></br>
          b) Anúncios e medição: ajuda a analisar comportamentos de navegação, a
          fim de veicular e medir anúncios, torná-los mais relevantes e analisar
          o conteúdo e uso de produtos e serviços;<br></br>
          c) Segurança e integridade: ajuda a manter a segurança dos serviços,
          oferecendo suporte ou ativando recursos de segurança e ajudando a
          detectar atividades que violam nossas políticas legais;<br></br>
          d) Recursos e serviços: ajuda a fornecer produtos e serviços e
          conteúdo personalizado;<br></br>
          e) Desempenho: ajuda a proporcionar a melhor experiência possível, por
          exemplo, ajudando a rotear o tráfego entre servidores e perceber com
          que velocidade o serviço é carregado para diferentes pessoas.
          Ocasionalmente, poderão ser armazenadas informações no navegador ou
          dispositivo para que os recursos em uso carreguem e respondam
          rapidamente.<br></br>
          f) Análises e pesquisas: ajuda a entender, aprimorar e pesquisar
          produtos e serviços quando você acessa a plataforma {empresa}.
          <br></br>
          2.2. O usuário poderá gerenciar as suas preferências de cookies e
          outras tecnologias semelhantes a partir da configuração de seu
          navegador ou dispositivo, recusando ou excluindo determinados cookies
          e outras tecnologias. Contudo, isso poderá comprometer a prestação dos
          serviços ou impedir o funcionamento de determinadas funcionalidades
          que os integram. Abaixo, listamos alguns endereços eletrônicos onde o
          usuário poderá gerenciar tais preferências:<br></br>-
          https://support.google.com/chrome/answer/95647 (Chrome);<br></br>-
          https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
          (Firefox);<br></br>-
          https://www.opera.com/help/tutorials/security/cookies/ (Opera);
          <br></br>-
          https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
          (Internet Explorer);<br></br>-
          https://support.apple.com/pt-br/guide/safari/sfri11471/mac (Safari);
          <br></br>-
          https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
          (Edge).<br></br>
        </p>
        <h4>3. Cookies de terceiros</h4>
        <p>
          3.1. Os provedores de serviços utilizados pela {empresa} poderão
          utilizar cookies e outras tecnologias de sua propriedade para
          identificar o navegador e dispositivo utilizados, de modo a oferecer
          publicidade direcionada da {empresa} quando o usuário acessa websites
          ou aplicativos de terceiros. Esses terceiros podem também fornecer à{" "}
          {empresa} informações acerca do desempenho das campanhas de marketing
          desenvolvidas.<br></br>
          3.2. O usuário poderá realizar o "opt-out" (exclusão voluntária) dos
          serviços de publicidade de terceiro acima mencionados através das
          ferramentas:<br></br>
          a) "YourAdChoices"{" "}
          <a
            href="http://optout.aboutads.info/?c=2&lang=EN"
            rel="noopener noreferrer"
            target="_blank"
          >
            http://optout.aboutads.info/?c=2&lang=EN
          </a>
          b) "Evidon"{" "}
          <a
            href="http://info.evidon.com/companies?cc=pt_br&external_opt_out=true&opt_out=true&opt_out_only=true"
            rel="noopener noreferrer"
            target="_blank"
          >
            http://info.evidon.com/companies?cc=pt_br&external_opt_out=true&opt_out=true&opt_out_only=true
          </a>
        </p>
        <h4>4. Tecnologias de rastreamento</h4>
        <p>
          4.1. A {empresa} poderá utilizar outras tecnologias de rastreamento,
          como web beacons (às vezes chamados de "pixels de rastreamento",
          “pixel tags” ou "gifs nítidos"). Essas tecnologias também ajudam os
          usuários Organizadores a saber mais sobre o interesse em determinados
          eventos. Em muitos casos, essas tecnologias dependem dos cookies para
          funcionar corretamente. <br></br>
          4.2. A {empresa} poderá utilizar uma "URL click-through" vinculada ao
          conteúdo da plataforma {empresa}. Ao clicar em uma dessas URL’s, os
          usuários poderão ter dados pessoais tratados, com base no legítimo
          interesse da {empresa}, para entendermos o interesse em determinados
          tópicos e avaliar a eficácia das comunicações com os usuários.
        </p>
        <h4>5. Disposições gerais</h4>
        <p>
          5.1. Ao navegar pela plataforma {empresa} e utilizar suas
          funcionalidades, os usuários aceitam todo o disposto nesta Política e
          demais políticas legais que se encontram vigentes na data de acesso.
          Por isso, é recomendável que os usuários se mantenham atualizados.{" "}
          <br></br>
          5.2. A presente Política de Cookies está sujeita a constante melhoria
          e aprimoramento. Assim, a {empresa} se reserva o direito de
          modificá-la a qualquer momento, conforme a finalidade da plataforma{" "}
          {empresa}, tal qual para adequação e conformidade legal de disposição
          de lei ou norma que tenha força jurídica equivalente, cabendo aos
          usuários verificá-la sempre que efetuar o acesso à plataforma{" "}
          {empresa}.<br></br>
          5.3. A eventual tolerância quanto a qualquer violação dos termos e
          condições do contido neste domínio será considerada mera liberalidade
          e não será interpretada como novação, precedente invocável, renúncia a
          direitos, alteração tácita dos termos contratuais, direito adquirido
          ou alteração contratual.<br></br>
          5.4. Caso alguma disposição desta Política for julgada inaplicável ou
          sem efeito, o restante das normas continua a viger, sem a necessidade
          de medida judicial que declare tal assertiva. Os termos aqui descritos
          serão interpretados segundo a legislação brasileira.<br></br>
          5.5. A comunicação entre a {empresa} e o usuário deverá ser realizada
          pelos canais de atendimento indicados e disponibilizados na plataforma{" "}
          {empresa}, sobretudo através do endereço de e-mail {email}.<br></br>
          5.6. Fica eleito o foro da Comarca de Fortaleza, Estado do Ceará, para
          dirimir quaisquer dúvidas oriundas do presente Contrato, excluindo-se
          qualquer outro foro, por mais privilegiado que seja.
        </p>
      </div>
    </div>
  );
}
